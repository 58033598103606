var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_vm.hasPermissionApprove && (_vm.isOwner || _vm.isOwnerHeykama || _vm.isGM) ? _c('pusat-modal', {
    on: {
      "submit": _vm.approve
    }
  }) : _vm._e(), _c('b-card', {
    staticClass: "mb-2"
  }, [_c('header', {
    staticClass: "d-flex justify-content-between align-items-center mb-1"
  }, [_c('div', {
    staticClass: "d-flex align-item-center"
  }, [_c('p', [_vm._v("Tanggal Retur: " + _vm._s(_vm.retur ? _vm.humanDate(_vm.retur.tanggal) : ''))])]), _c('div', [!_vm.hasPermissionApprove && _vm.isAdminGudang && _vm.retur.lapor == 0 ? _c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.laporkan($event);
      }
    }
  }, [_vm._v(" Laporkan ")]) : _vm._e(), _vm.hasPermissionApprove && (_vm.isOwner || _vm.isOwnerHeykama || _vm.isGM) && _vm.retur.approve == 0 ? _c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.openpusatModal($event);
      }
    }
  }, [_vm._v("Setujui Retur")]) : _vm.hasPermissionApprove && (_vm.isOwner || _vm.isOwnerHeykama || _vm.isGM) && _vm.retur.approve > 0 ? _c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "outline-danger"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.cancel($event);
      }
    }
  }, [_vm._v("Batalkan")]) : _vm._e()], 1)]), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('span', {
    staticClass: "d-block"
  }, [_vm._v("Pembuat")]), _c('strong', {
    staticClass: "d-block mb-1"
  }, [_vm._v(_vm._s(_vm.retur.pembuat ? _vm.retur.pembuat.nama_lengkap : '-'))]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v("Keterangan")]), _c('strong', {
    staticClass: "d-block mb-2"
  }, [_vm._v(_vm._s(_vm.retur ? _vm.retur.keterangan : ''))])]), (_vm.isOwner || _vm.isOwnerHeykama || _vm.isGM) && _vm.hasPermissionApprove ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('span', {
    staticClass: "d-block"
  }, [_vm._v("Info Gudang")]), _c('strong', {
    staticClass: "d-block mb-1"
  }, [_vm._v(_vm._s(_vm.retur.gudang ? _vm.retur.gudang.nama_gudang : '-'))]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v("Informasi pusat")]), _c('strong', {
    staticClass: "d-block mb-1"
  }, [_vm._v(_vm._s(_vm.retur && _vm.retur.pusat ? _vm.retur.pusat.instansi : '-'))])]) : _vm._e(), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_vm.retur.lapor == 1 ? _c('b-badge', {
    staticClass: "mb-1",
    attrs: {
      "variant": "success"
    }
  }, [_vm._v("Sudah dilaporkan")]) : _c('b-badge', {
    staticClass: "mb-1",
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v("Belum dilaporkan")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v("Status")]), _c('b-badge', {
    staticClass: "mb-1",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(_vm._s(_vm.retur ? _vm.retur.status : ''))])], 1)], 1)], 1), _c('h4', {
    staticClass: "mb-1"
  }, [_vm._v("Rincian Barang"), _vm.retur.rincian && _vm.retur.rincian.length > 0 ? _c('span', [_vm._v("(" + _vm._s(_vm.retur.rincian.length) + ")")]) : _vm._e()]), _c('b-card', {
    staticClass: "mb-2"
  }, [_c('b-table', {
    attrs: {
      "responsive": "",
      "fields": _vm.fields,
      "items": _vm.retur.rincian
    },
    scopedSlots: _vm._u([{
      key: "cell(barang)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.barang ? _c('span', [_vm._v(" " + _vm._s(item.barang.nama) + " ")]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Barang tidak ada.")])];
      }
    }, {
      key: "cell(satuan)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.barang && item.barang.satuan ? item.barang.satuan.satuan : '') + " ")];
      }
    }])
  })], 1), _c('section', {
    staticClass: "d-flex align-items-center justify-content-end"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }